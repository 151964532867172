import {
    FC,
    memo,
    useMemo,
    useCallback,
    useState,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';

import { getNotificationsFromSlice } from 'entities/notification';
import { getStateIsEditPost } from 'entities/user';
import { IArgsContentViewer } from 'features/content-viewer';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IVariable } from 'shared/types/channels';
import {
    EPostTypeMessage,
    TMessage,
} from 'shared/types/message';
import { Flex } from 'shared/ui/flex/Flex';

import {
    IMessageOptions,
} from '../model/types/message.types';
import { renderHTMLString } from '../utils/deserialize';

import { Album } from './album/Album';
import { MessageButtonList } from './buttons/MessageButtonList';
import { ContentTypeRenderer } from './content-types-renderer/ContentTypeRenderer';
import { ControlError } from './control-error/ControlError';
import { HolderContentMessage } from './holder-content/HolderContentMessage';
import { Markup } from './markup/Markup';
import { ResendLoader } from './resend-loader/ResendLoader';

import cls from './Message.module.scss';

interface IMessageProps {
    className?: string;
    message: TMessage;
    onEditMessage: (mes: TMessage) => void;
    timezone?: string;
    variables: IVariable[];
    openModalToRemoveMessage: (mesId: string, importedFrom: string | null) => void;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
    reloadMessages: Function;
    reloadChannels: Function;
}

export const Message: FC<IMessageProps> = memo((props) => {
    const {
        className,
        message,
        onEditMessage,
        openModalToRemoveMessage,
        openPreviewVideoOrPhoto,
        reloadChannels,
        reloadMessages,
        timezone,
        variables,
    } = props;

    const holderContentRef = useRef<Element>(null);

    const text = message?.text;
    const isSent = message?.isSent;
    const errors = message?.errors;
    const hasErrors = errors?.length > 0;

    const notifications = useSelector(getNotificationsFromSlice.selectAll);
    const isEditPostRight = useSelector(getStateIsEditPost);

    const hasNotifications = notifications.length > 0;

    const [visiblePopover, setVisiblePopover] = useState(false);
    const [isResend, setIsResend] = useState(false);

    const markup = useMemo(
        () => (text ? (
            <div className={classNames(cls.textMes, {}, ['fontWithStickers'])}>
                {
                    renderHTMLString(text, variables)
                }
            </div>
        ) : null),
        [text, variables],
    );

    const options = useMemo((): IMessageOptions => {
        const withDocument = !!message?.post?.media?.some((media) => media.type === 'document');
        const withMediaPhotoVideo = !!message?.post?.media?.some(
            (media) => media.type === 'video' || media.type === 'photo',
        );

        const isAnimSticker = message.postType === EPostTypeMessage.ANIMATED_STICKER;
        const isVoiceMessage = message.postType === EPostTypeMessage.VOICE_MESSAGE;
        const isAudioMessage = message.postType === EPostTypeMessage.AUDIO_MESSAGE;
        const isStaticSticker = message.postType === EPostTypeMessage.STATIC_STICKER;
        const isCustomSticker = message.postType === EPostTypeMessage.CUSTOM_STICKER;
        const isVideoSticker = message.postType === EPostTypeMessage.VIDEO_STICKER;
        const isRoundedVideo = message.postType === EPostTypeMessage.ROUND_VIDEO;
        const isAlbum = message.postType === EPostTypeMessage.ALBUM;
        const isPoll = message.postType === EPostTypeMessage.POLL;

        const withMediaWithoutText = withMediaPhotoVideo && !text;

        const isWebPreview = message?.post?.media?.length === 1
            && !!withMediaPhotoVideo
            && message.webPreviewLimitsBypassEnabled
            && !!text;

        const isOnlySticker = isAnimSticker || isStaticSticker || isVideoSticker || isCustomSticker;

        return {
            isAlbum,
            isAnimSticker,
            isAudioMessage,
            isOnlySticker,
            isPoll,
            isRoundedVideo,
            isStaticSticker,
            isVideoSticker,
            isVoiceMessage,
            isWebPreview,
            withDocument,
            withMediaPhotoVideo,
            withMediaWithoutText,
        };
    }, [text, message]);

    const isWithoutTextAndOnlyMedia = useMemo(
        () => (!markup || options.isRoundedVideo)
            && (options.withMediaPhotoVideo || options.isOnlySticker || options.isRoundedVideo),
        [markup, options],
    );

    const isDisabledPopover = useMemo(() => !isEditPostRight, [isEditPostRight]);

    const messageClasses = {
        [cls.withoutText]: !markup || options.isRoundedVideo,
        [cls.onlyMedia]: options.withMediaPhotoVideo || options.isOnlySticker || options.isRoundedVideo,
        [cls.withoutBg]: options.isOnlySticker || options.withMediaWithoutText || options.isRoundedVideo,
        [cls.webPreview]: options.isWebPreview,
        [cls.withoutPaddingB]: options.isOnlySticker || options.isVoiceMessage || options.isAudioMessage,
    };

    const handleToggleModalOnDelete = useCallback(() => {
        openModalToRemoveMessage(message.id, message?.importedFrom);
    }, [openModalToRemoveMessage]);

    const handleTogglePopover = useCallback(() => {
        if (isDisabledPopover) {
            return;
        }

        setVisiblePopover((prev) => !prev);
    }, [isDisabledPopover]);

    return (
        <li
            className={classNames(cls.messageWrapper, {
                [cls.messageWrapperAlbum]: options.isAlbum,
                [cls.messageWrapperWithError]: hasNotifications,
            })}
            id={message.id}
        >
            <HolderContentMessage
                message={message}
                onEditMessage={onEditMessage}
                handleToggleModalOnDelete={handleToggleModalOnDelete}
                visiblePopover={visiblePopover}
                setVisiblePopover={setVisiblePopover}
                timezone={timezone}
                holderContentRef={holderContentRef}
                handleTogglePopover={handleTogglePopover}
                isDisabledPopover={isDisabledPopover}
                setIsResend={setIsResend}
                isSent={isSent}
                hasErrors={hasErrors}
            >
                <Flex
                    align="start"
                    direction="column"
                    className={classNames(cls.message, messageClasses, [className])}
                    onClick={handleTogglePopover}
                    ref={holderContentRef}
                >
                    <Album
                        message={message}
                        options={options}
                        isWithoutTextAndOnlyMedia={isWithoutTextAndOnlyMedia}
                        openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                    />
                    <ContentTypeRenderer message={message} timezone={timezone} />

                    <Markup
                        message={message}
                        options={options}
                        timezone={timezone}
                        markup={markup}
                        isWithoutTextAndOnlyMedia={isWithoutTextAndOnlyMedia}
                    />

                    {errors.length > 0 && !isResend && <ControlError errorsList={errors} />}
                    <ResendLoader
                        isResend={isResend}
                        message={message}
                        setIsResend={setIsResend}
                        reloadMessages={reloadMessages}
                        reloadChannels={reloadChannels}
                    />
                </Flex>
            </HolderContentMessage>

            <MessageButtonList buttonList={message?.buttons} variables={variables} />
        </li>
    );
});
