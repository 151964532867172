import { Dayjs } from 'dayjs';

import { INotification } from './notification';

export enum EPublishStatus {
    ERROR = 'error',
    IN_PROGRESS = 'in_progress',
    SEND_SUCCEEDED = 'send_succeeded',
    NOT_STARTED = 'not_started',
}

export enum EPostTypeMessage {
    TEXT = 'text',
    UNKNOWN = 'unknown',
    ALBUM = 'album',
    ANIMATED_STICKER = 'animated_sticker',
    AUDIO_MESSAGE = 'audio_message',
    VOICE_MESSAGE = 'voice_message',
    STATIC_STICKER = 'static_sticker',
    VIDEO_STICKER = 'video_sticker',
    ROUND_VIDEO = 'round_video',
    CUSTOM_STICKER = 'custom_sticker',
    POLL = 'poll',
}

export enum EMediaMessageTypes {
    VIDEO = 'video',
    PHOTO = 'photo',
    DOCUMENT = 'document',
}

export type TValueBtnType = 'url' | 'variable';

export enum EScrollTypes {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export interface IFile {
    id: string;
    name: string;
    fileSize: number;
    thumbnail: string;
    createdAt: string;
    updatedAt: string;
    file: string;
    type?: string;
}

export interface IFileFromServer {
    id: string;
    name: string;
    file_size: number;
    created_at: string;
    updated_at: string;
    file: string;
    thumbnail: string;
    type?: string;
}

export interface IFileWithTypeAndUID extends IFile {
    uid: string;
    type: string;
}

export interface IPostMedia {
    type: string;
    file: IFile;
}

export interface IPostPollData {
    answers: IPostPollAnswers[];
    multipleChoice: boolean;
    question: string;
    quiz: boolean;
    solutionIndex: number | null;
    solutionMessage?: string;
}

type TValueBtn = {
    type: TValueBtnType;
    data: string;
};

export interface IMessageButton {
    id?: string; // Custom value which we define ourself
    name: string;
    value: TValueBtn;
}

export interface IDefaultMessage {
    id: string;
    publishAt: string | Dayjs;
    postType: EPostTypeMessage;
    text?: string;
    post: {
        media?: IPostMedia[];
        file?: IFile;
        poll?: IPostPollData;
    };
    createdAt: string;
    updatedAt: string;
    channel: string;
    buttons?: IMessageButton[];
    webPreviewLimitsBypassEnabled: boolean;
    isNotifyPin: boolean;
    isPinIndefinitely: boolean;
    pinDuration: number | null;
    donorMessageId?: number;
    isEdited: boolean;
    isSent: boolean;
    errors: INotification[];
    postPublishStatus: EPublishStatus;
    importedFrom: string | null;
}

export interface ITextMessage extends IDefaultMessage {
    text?: string;
    postType: EPostTypeMessage.TEXT;
}

export interface IUnknownMessage extends IDefaultMessage {
    postType: EPostTypeMessage.UNKNOWN;
}
export interface IAlbumMessage extends IDefaultMessage {
    postType: EPostTypeMessage.ALBUM;
}
export interface IStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.ANIMATED_STICKER;
    post: {
        media?: IPostMedia[];
        file?: IFile;
        sticker_height?: number | null;
        sticker_width?: number | null;
    };
}
export interface IAudioMessage extends IDefaultMessage {
    postType: EPostTypeMessage.AUDIO_MESSAGE;
}
export interface IVoiceMessage extends IDefaultMessage {
    postType: EPostTypeMessage.VOICE_MESSAGE;
}
export interface IVideoStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.VIDEO_STICKER;
    post: IMessagePost;
}
export interface IStaticStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.STATIC_STICKER;
    post: IMessagePost;
}

export interface ICustomStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.CUSTOM_STICKER;
    post: IMessagePost;
}
export interface IRoundVideoMessage extends IDefaultMessage {
    postType: EPostTypeMessage.ROUND_VIDEO;
}

export interface IPollMessage extends IDefaultMessage {
    postType: EPostTypeMessage.POLL;
    post: IMessagePost;
}

export type TMessage =
    | ITextMessage
    | IUnknownMessage
    | IAlbumMessage
    | IStickerMessage
    | IAudioMessage
    | IVoiceMessage
    | IVideoStickerMessage
    | IStaticStickerMessage
    | IRoundVideoMessage
    | ICustomStickerMessage
    | IPollMessage;

export type CustomVideoElementRef = HTMLVideoElement & {
    webkitEnterFullscreen(): Promise<void>;
    requestFullscreen(): Promise<void>;
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
};

export interface IMessagePost {
    text?: string;
    media?: IPostMedia[];
    file?: IFile;
    stickerHeight?: number | null;
    stickerWidth?: number | null;
    poll?: IPostPollData;
}

export interface ICreateMessageFile {
    fileId?: string;
    type?: string;
}

export interface IPostPollAnswers {
    value: string;
    id?: string;
}
