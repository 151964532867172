import {
    FC,
    MouseEvent,
} from 'react';

import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import { Flex } from '../flex/Flex';
import { Text } from '../text/Text';

import cls from './OverlayFileLoading.module.scss';

interface IOverlayFileLoadingProps {
    className?: string;
    isAlwaysShow?: boolean;
    withStopPropagation?: boolean;
    onlyIcon?: boolean;
    onClick?: (...args: any[]) => void;
}

export const OverlayFileLoading: FC<IOverlayFileLoadingProps> = (props) => {
    const {
        className, isAlwaysShow, onClick, onlyIcon,
        withStopPropagation,
    } = props;

    const updatePage = (e: MouseEvent<HTMLSpanElement>) => {
        if (withStopPropagation) {
            e.stopPropagation();
        }

        window.location.reload();
    };

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            className={classNames(cls.overlayLoading, { [cls.alwaysShow]: isAlwaysShow }, [className])}
        >
            <Flex direction="column" gaps="8" className={cls.overlayLoadingWrapper}>
                <ClockCircleOutlined />

                {!onlyIcon && (
                    <Text
                        text={(
                            <>
                                Файл загружеается,
                                {' '}
                                <span
                                    role="button"
                                    className={cls.updatePage}
                                    onClick={updatePage}
                                    tabIndex={0}
                                >
                                    обновите страницу
                                </span>
                                {' '}
                                позже
                            </>
                        )}
                        className={cls.fileLoadingText}
                        align="center"
                    />
                )}
            </Flex>
        </div>
    );
};
