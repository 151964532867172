import { Options } from 'react-lottie';

import { Dayjs } from 'dayjs';

import { EntityState } from '@reduxjs/toolkit';
import { EBehaviorScroll } from 'shared/constants/scroll';
import {
    EPostTypeMessage,
    EPublishStatus,
    ICreateMessageFile,
    IFileFromServer,
    IMessageButton,
    IPostPollData,
    TMessage,
} from 'shared/types/message';
import { INotification } from 'shared/types/notification';

export interface IPostMediaFromServer {
    type: string;
    file: IFileFromServer;
}

export interface ISearchMessage {
    cursorLink: string;
    id: string;
}

export interface ISearchMessageFromServer {
    cursor_link: string;
    id: string;
}

export enum EValueBtnType {
    URL = 'url',
    VARIABLE = 'variable',
}

export interface IMessageFromServer {
    id: string;
    post: IMessagePostFromServer;
    post_type: EPostTypeMessage;
    publish_at: string | Dayjs;
    created_at: string;
    updated_at: string;
    channel: string;
    type?: string;
    buttons: IMessageButton[];
    web_preview_limits_bypass_enabled: boolean;
    notify_on_pin: boolean;
    pin_duration: null | number;
    pin_indefinitely: boolean;
    donor_message_id?: number;
    is_edited: boolean;
    is_sent: boolean;
    errors: INotification[];
    post_publish_status: EPublishStatus;
    imported_from: string | null;
}

export type TUpdatingMessageStatuses = 'shouldUpdate' | 'pending' | 'updating' | 'updated';

export interface IMessagesSchema extends EntityState<TMessage> {
    messageUpdatingStatus: TUpdatingMessageStatuses;
    scrollMessageId: string | null;
    nextUrlMessage: string | null;
    previousUrlMessage: string | null;
    cacheAnimationSticker: ICacheAnimationSticker;
    isAnimationScrollMessage: boolean;
    behaviorScrollMessage: EBehaviorScroll;
    openFromChannelList: boolean;
    dateSearch?: string;
    shouldUpdateMessage: boolean;
    errorUpdateMessage: boolean;
    isMessageListUpdate: boolean;
    playingFileHashId: string | null;
    isDisabledScroll: boolean;
    indexStartMes: number;
}

export interface ICacheAnimationSticker {
    [key: string]: Options;
}

export interface IMessageOptions {
    isAnimSticker: boolean;
    isAudioMessage: boolean;
    isRoundedVideo: boolean;
    isStaticSticker: boolean;
    isVideoSticker: boolean;
    isVoiceMessage: boolean;
    isWebPreview: boolean;
    withDocument: boolean;
    withMediaPhotoVideo: boolean;
    withMediaWithoutText: boolean;
    isOnlySticker: boolean;
    isAlbum: boolean;
    isPoll: boolean;
}

export interface IGroupedMessages {
    publishAt: string;
    items: TMessage[];
}
export interface IResponseResendPost {
    status: string;
}

export interface IGetMessagesSearchArgs {
    channelId: string;
    date?: string;
    search?: string;
    cursor?: string;
}
export interface IMessagePostFromServer {
    text?: string;
    media?: IPostMediaFromServer[];
    file?: IFileFromServer;
    sticker_height?: number | null;
    sticker_width?: number | null;
    poll?: IPostPollDataFromServer;
}

export interface IPostPollDataFromServer {
    answers: string[];
    multiple_choice: boolean;
    question: string;
    quiz: boolean;
    solution_index: number | null;
    solution_message?: string;
}

export interface ICreateMessageFileFromServer {
    file_id?: string;
    type?: string;
}

export interface ICreateMessagePost {
    media?: ICreateMessageFile[];
    fileId?: string;
    type?: string;
    text?: string;
    poll?: IPostPollData;
}

export interface ICreateMessagePostFromServer {
    media?: ICreateMessageFileFromServer[];
    file_id?: string;
    type?: string;
    text?: string;
    poll?: IPostPollDataFromServer;
}

export interface ICreateMessageFromServer {
    channel: string;
    notify_on_pin: boolean;
    pin_duration: null | number;
    pin_indefinitely: boolean;
    post_type: EPostTypeMessage;
    publish_at: string | Dayjs;
    web_preview_limits_bypass_enabled: boolean;
    buttons: IMessageButton[];
    post?: ICreateMessagePostFromServer;
    id?: string;
}
export interface ICreateMessage {
    channel: string;
    notifyOnPin: boolean;
    pinDuration: null | number;
    pinIndefinitely: boolean;
    postType: EPostTypeMessage;
    publishAt: string | Dayjs;
    webPreviewLimitsBypassEnabled: boolean;
    buttons: IMessageButton[];
    post?: ICreateMessagePost;
    id?: string;
}
