import {
    Dispatch,
    FC,
    SetStateAction,
    Suspense,
    memo,
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Link,
} from 'react-router-dom';

import {
    Button,
} from 'antd';

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import {
    channelActions,
} from 'entities/channels';
import {
    getMessagesShouldUpdate,
    messagesActions,
} from 'entities/message';
import { getStateIsChannelEdition } from 'entities/user';
import { ImportMessages } from 'features/import-messages';
import { SearchMessages } from 'features/search-messages';
import { EDIT_CHANNEL_URL } from 'shared/constants/router';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { getFullTimezoneName } from 'shared/lib/utils/timezones/timezones';
import { IChannel } from 'shared/types/channels';
import { TMessage } from 'shared/types/message';
import { Flex } from 'shared/ui/flex/Flex';
import { MiniHeader } from 'shared/ui/mini-header/MiniHeader';
import { Text } from 'shared/ui/text/Text';

import cls from './ChannelPageHeader.module.scss';

interface IChannelPageHeaderProps {
    className?: string;
    channel?: IChannel;
    channelId: IChannel['id'];
    isLoading: boolean;
    channelName: IChannel['name'];
    isShowSearchContent: boolean;
    setIsShowSearchContent: Dispatch<SetStateAction<boolean>>;
    handleShowMessage: (id: TMessage['id'], page?: string, behaviorScroll?: EBehaviorScroll) => void;
}

export const ChannelPageHeader: FC<IChannelPageHeaderProps> = memo((props) => {
    const {
        channel,
        channelId,
        channelName,
        className,
        handleShowMessage,
        isLoading,
        isShowSearchContent,
        setIsShowSearchContent,
    } = props;

    const showUpdatesPost = false; // TODO: добавить функционал на отображение линии прогресса импрота

    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);
    const isChannelEditionRight = useSelector(getStateIsChannelEdition);

    const timezoneText = useMemo(() => (
        getFullTimezoneName(channel?.timezone || '')
    ), [channel?.timezone]);

    const dispatch = useAppDispatch();

    const updatingStatusPending = useMemo(() => (messageUpdatingStatus === 'pending'), [messageUpdatingStatus]);

    const handleOpenSearchContent = useCallback(() => {
        setIsShowSearchContent(true);
    }, []);

    const openEditChannelPage = useCallback(() => {
        dispatch(messagesActions.updateOpenFromChannelList(false));
    }, []);

    const handleOpenCreateModal = useCallback(() => {
        dispatch(channelActions.setOpenCreateMessageModal(true));
    }, [dispatch]);

    return (
        <MiniHeader className={classNames(cls.channelPageHeader, {}, [className])}>
            <div className={cls.left}>
                <Flex direction="column" align="start" max>
                    <Text size="md" className={cls.title} text={channelName} withEllipsis max />

                    <Text text={timezoneText} theme="black-45" withEllipsis max tooltip={timezoneText} />
                </Flex>
                <div className={cls.blockHidingText} />
            </div>

            <div className={cls.right}>
                {channel?.donorChatId && <ImportMessages channel={channel} isDisabled={isLoading} />}
                <Button
                    icon={(
                        <PlusCircleOutlined />
                    )}
                    disabled={updatingStatusPending}
                    onClick={handleOpenCreateModal}
                />
                <Button
                    icon={<SearchOutlined />}
                    disabled={isLoading || updatingStatusPending}
                    onClick={handleOpenSearchContent}
                />
                {isChannelEditionRight && (
                    <Link to={`${EDIT_CHANNEL_URL}/${channelId}`} onClick={openEditChannelPage}>
                        <Button
                            icon={(
                                <SettingOutlined />
                            )}
                        />
                    </Link>
                )}
            </div>

            <Suspense fallback={<div />}>
                <SearchMessages
                    show={isShowSearchContent}
                    setShow={setIsShowSearchContent}
                    channelId={channelId}
                    handleShowMessage={handleShowMessage}
                />
            </Suspense>

            {showUpdatesPost && (
                <div className={cls.holerLine}>
                    <div className={cls.line} style={{ width: '50%' }} />
                </div>
            )}
        </MiniHeader>
    );
});
