import { transformApiListResponse } from 'shared/lib/utils/api/api';
import { removeUndefinedKeys } from 'shared/lib/utils/object/object';
import {
    EPostTypeMessage,
    IFile,
    IFileFromServer,
    IMessagePost,
    IPostMedia,
    IPostPollAnswers,
    IPostPollData,
    TMessage,
} from 'shared/types/message';
import { IBackendListResponse } from 'shared/types/types';

import {
    ICreateMessage,
    ICreateMessageFromServer,
    ICreateMessagePost,
    ICreateMessagePostFromServer,
    IMessageFromServer,
    IMessagePostFromServer,
    IPostMediaFromServer,
    ISearchMessage,
    ISearchMessageFromServer,
    IPostPollDataFromServer,
} from '../model/types/message.types';

export const transformMessageFile = (file: IFileFromServer): IFile => ({
    createdAt: file.created_at,
    file: file.file,
    fileSize: file.file_size,
    id: file.id,
    name: file.name,
    thumbnail: file.thumbnail,
    type: file.type,
    updatedAt: file.updated_at,
});

export const transformMessageMedia = (media: IPostMediaFromServer): IPostMedia => ({
    file: transformMessageFile(media.file),
    type: media.type,
});

export const transformMessageMediaList = (media?: IPostMediaFromServer[]): IPostMedia[] | undefined => {
    if (!media) {
        return undefined;
    }

    return media.map((file) => transformMessageMedia(file));
};

export const transformMessagePollAnswers = (value: IPostPollAnswers['value'], index: number): IPostPollAnswers => ({
    id: index.toString(),
    value,
});

export const transformMessagePollData = (data?: IPostPollDataFromServer): IPostPollData | undefined => {
    if (!data) {
        return data;
    }

    return {
        answers: data.answers.map(transformMessagePollAnswers),
        multipleChoice: data.multiple_choice,
        question: data.question,
        quiz: data.quiz,
        solutionIndex: data.solution_index,
        solutionMessage: data.solution_message,
    };
};

export const transformMessagePost = (post: IMessagePostFromServer): IMessagePost => removeUndefinedKeys({
    file: post?.file ? transformMessageFile(post.file) : undefined,
    media: transformMessageMediaList(post?.media),
    poll: transformMessagePollData(post?.poll),
    stickerHeight: post?.sticker_height,
    stickerWidth: post?.sticker_width,
    text: post?.text,
});

export function mapMessage(rawPost: IMessageFromServer): TMessage {
    const buttons = rawPost?.buttons?.map((el, i) => ({ ...el, id: `${i}` }));

    if (rawPost.post_type === EPostTypeMessage.UNKNOWN) {
        return {
            buttons,
            channel: rawPost.channel,
            createdAt: rawPost.created_at,
            donorMessageId: rawPost.donor_message_id,
            errors: rawPost.errors,
            id: rawPost.id as string,
            importedFrom: rawPost.imported_from,
            isEdited: rawPost.is_edited,
            isNotifyPin: rawPost.notify_on_pin,
            isPinIndefinitely: rawPost.pin_indefinitely,
            isSent: rawPost.is_sent,
            pinDuration: rawPost.pin_duration,
            post: transformMessagePost(rawPost.post),
            postPublishStatus: rawPost.post_publish_status,
            postType: EPostTypeMessage.TEXT,
            publishAt: rawPost.publish_at,
            text: 'The message is not supported',
            updatedAt: rawPost.updated_at,
            webPreviewLimitsBypassEnabled: rawPost.web_preview_limits_bypass_enabled,
        };
    }

    return {
        buttons,
        channel: rawPost.channel,
        createdAt: rawPost.created_at,
        donorMessageId: rawPost.donor_message_id,
        errors: rawPost.errors,
        id: rawPost.id,
        importedFrom: rawPost.imported_from,
        isEdited: rawPost.is_edited,
        isNotifyPin: rawPost.notify_on_pin,
        isPinIndefinitely: rawPost.pin_indefinitely,
        isSent: rawPost.is_sent,
        pinDuration: rawPost.pin_duration,
        post: transformMessagePost(rawPost.post),
        postPublishStatus: rawPost.post_publish_status,
        postType: rawPost.post_type,
        publishAt: rawPost.publish_at,
        text: rawPost.post?.text as string,
        updatedAt: rawPost.updated_at,
        webPreviewLimitsBypassEnabled: rawPost.web_preview_limits_bypass_enabled,
    };
}

export const transformGetMessage = async (rawResult:
IMessageFromServer) => mapMessage(rawResult);

export const transformGetMessages = async (rawResult:
IBackendListResponse<IMessageFromServer>) => transformApiListResponse<IMessageFromServer, TMessage>(rawResult, mapMessage);

const transformPollData = (poll: IPostPollData): IPostPollDataFromServer => ({
    answers: poll.answers.map(({ value }) => value.trim()),
    multiple_choice: poll.multipleChoice,
    question: poll.question,
    quiz: poll.quiz,
    solution_index: poll.solutionIndex,
    solution_message: poll.solutionMessage,
});

const transformCreateMessagePost = (post?: ICreateMessagePost)
: ICreateMessagePostFromServer | undefined => removeUndefinedKeys({
    file_id: post?.fileId,
    media: post?.media ? post.media?.map((item) => ({ file_id: item.fileId, type: item.type })) || [] : undefined,
    poll: post?.poll ? transformPollData(post?.poll) : undefined,
    text: post?.text,
    type: post?.type,
});

export const transformBodyCreateMessage = (body: ICreateMessage): ICreateMessageFromServer => {
    const id = body.id ? { id: body.id } : {};

    const post = transformCreateMessagePost(body.post);

    return {
        buttons: body.buttons,
        channel: body.channel,
        notify_on_pin: body.notifyOnPin,
        pin_duration: body.pinDuration,
        pin_indefinitely: body.pinIndefinitely,
        post,
        post_type: body.postType,
        publish_at: body.publishAt,
        web_preview_limits_bypass_enabled: body.webPreviewLimitsBypassEnabled,
        ...id,
    };
};

export function mapMessageSearch(rawMessageSearch: ISearchMessageFromServer): ISearchMessage {
    return {
        cursorLink: rawMessageSearch.cursor_link,
        id: rawMessageSearch.id,
    };
}

export const transformGetMessagesSearch = async (rawResult:
IBackendListResponse<ISearchMessageFromServer>) => transformApiListResponse<ISearchMessageFromServer, ISearchMessage>(
    rawResult,
    mapMessageSearch,
);
