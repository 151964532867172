import React, {
    ReactNode,
    useCallback,
} from 'react';

import { Button } from 'antd';

import SyncOutlined from '@ant-design/icons/SyncOutlined';
import WarningFilled from '@ant-design/icons/WarningFilled';
import { messagesActions } from 'entities/message';
import { EHintUpdatePost } from 'features/import-messages';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './useGetInfoHint.module.scss';

interface IUseGetInfoHintProps {
    status: EHintUpdatePost | null;
}

interface IUseGetInfoHintReturn {
    button: ReactNode;
    icon: ReactNode;
    text: string;
    title: string;
}

export const useGetInfoHint = (props:IUseGetInfoHintProps): IUseGetInfoHintReturn => {
    const { status = EHintUpdatePost.UPDATED } = props;

    const dispatch = useAppDispatch();

    const icon = status === EHintUpdatePost.UPDATED
        ? <SyncOutlined className={classNames(cls.icon, {}, [cls.iconUpdated])} />
        : <WarningFilled className={classNames(cls.icon, {}, [cls.iconError])} />;

    const title = status === EHintUpdatePost.UPDATED
        ? 'Обновление постов'
        : 'Посты не обновлены';

    const text = status === EHintUpdatePost.UPDATED
        // eslint-disable-next-line max-len
        ? 'Мы начали обновлять посты, вам не нужно держать эту вкладку открытой. Чем больше постов и данных в них, тем больше времени это может занять.'
        : 'Что-то пошло не так и мы не смогли обновить посты на канале. Повторите попытку позже.';

    const onCancel = useCallback(() => {
        dispatch(messagesActions.updateErrorUpdateMessage(false));
    }, []);

    const handleUpdateMessage = useCallback(() => {
        onCancel();
        dispatch(messagesActions.updateShouldUpdateMessage(true));
    }, []);

    const button = status === EHintUpdatePost.ERROR && (
        <div className={cls.holderButton}>
            <Button
                block
                onClick={onCancel}
                type="default"
                size="large"
            >
                Отменить
            </Button>
            <Button
                block
                type="primary"
                size="large"
                onClick={handleUpdateMessage}
            >
                Обновить посты
            </Button>
        </div>
    );

    return (
        {
            button,
            icon,
            text,
            title,
        }
    );
};
