/**
 * Returns the appropriate declension for a given number based on provided text forms.
 *
 * This function is commonly used in languages like Russian or Polish where word forms
 * change based on the number they are associated with (e.g., 1 apple, 2 apples, 5 apples, etc.).
 *
 * @param {number} n - The number based on which the appropriate declension is determined.
 * @param {string[]} textForms - An array containing the three possible declensions for a word.
 *                                textForms[0] is used for singular (e.g., "apple"),
 *                                textForms[1] is used for a few (e.g., "apples" for numbers 2-4),
 *                                and textForms[2] is used for many (e.g., "apples" for numbers 5 and above).
 * @returns {string} The correct declension based on the given number.
 */
export function declensionOfNum(n: number, textForms: string[]) {
    n = Math.abs(n) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) { return textForms[2]; }
    if (n1 > 1 && n1 < 5) { return textForms[1]; }
    if (n1 === 1) { return textForms[0]; }

    return textForms[2];
}

export const capitalizeFirstLetter = (str: string): string => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
};

export const convertChatId = (channelId: number | string | null): string => {
    if (!channelId) return '';

    let idString = channelId.toString();

    if (idString.startsWith('-100')) {
        idString = idString.substring(4);
    }

    return idString;
};

export const validateNoSpaces = (_: any, value: string) => {
    if (!value || value.trim() !== '') {
        return Promise.resolve();
    }
    return Promise.reject(new Error('Введите текст'));
};
