import {
    FC,
    memo,
    useMemo,
} from 'react';

import PushpinFilled from '@ant-design/icons/PushpinFilled';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { formatDate } from 'shared/lib/utils/date/date';
import { TMessage } from 'shared/types/message';
import { Text } from 'shared/ui/text/Text';

import {
    IMessageOptions,
} from '../../model/types/message.types';

import cls from './AdditionalInfoMessage.module.scss';

interface IAdditionalInfoMessageProps {
    message: TMessage;
    timezone?: string;
    options: IMessageOptions;
    isWithoutTextAndOnlyMedia: boolean;
}

export const AdditionalInfoMessage: FC<IAdditionalInfoMessageProps> = memo((props) => {
    const {
        isWithoutTextAndOnlyMedia, message, options, timezone,
    } = props;
    const text = message?.text;
    const pinDuration = message?.pinDuration;
    const isPinIndefinitely = message?.isPinIndefinitely;
    const isSent = message?.isSent;
    const isEdited = message?.isEdited;

    const publicationBlock = useMemo(
        () => message.errors.some(({ publicationBlock: publicationBlockValue }) => publicationBlockValue),
        [message],
    );

    const date = message?.publishAt ? formatDate(message.publishAt, 'HH:mm', timezone, true) : '';

    const textAdditionalInfo = useMemo(() => {
        if (publicationBlock) {
            return <Text text="Не опубликовано" size="xs" className={cls.textPublicationBlock} />;
        }
        if (isSent) {
            return <Text text="Опубликовано" size="xs" className={cls.textSent} />;
        }
        if (isEdited) {
            return <Text text="Изменено" size="xs" className={cls.textEdited} />;
        }
        return null;
    }, [isSent, isEdited, publicationBlock]);

    const isPinnedMessage = useMemo(() => pinDuration || isPinIndefinitely, [pinDuration, isPinIndefinitely]);

    const classNameWrapperAdditionalInfo = useMemo(() => {
        if (options.isPoll) {
            return cls.wrapperAdditionalInfoPoll;
        }
        if (options.isWebPreview) {
            return cls.wrapperAdditionalInfoWebPreview;
        }
        if (options.isAnimSticker || options.isStaticSticker || options.isVideoSticker) {
            return cls.wrapperAdditionalInfoStikers;
        }
        if (options.isRoundedVideo) {
            return cls.wrapperAdditionalRoundVideo;
        }
        if (options.withMediaPhotoVideo && !options.withMediaWithoutText) {
            return cls.wrapperAdditionalInfoMedaiaWithoutText;
        }
        if (options.isVoiceMessage || options.isAudioMessage) {
            return cls.wrapperAdditionalInfoVoiceMessage;
        }
        if (options.withDocument && !text) {
            return cls.wrapperAdditionalInfoOnlyDocument;
        }
        return '';
    }, [options, text]);

    return (
        <span
            className={classNames(cls.additionalInfo, { [cls.withoutTextAndOnlyMedia]: isWithoutTextAndOnlyMedia }, [
                classNameWrapperAdditionalInfo,
            ])}
        >
            <div className={cls.holderAdditionalInfo}>
                {textAdditionalInfo}
                {isPinnedMessage && <PushpinFilled />}
                <span className={classNames(cls.additionalInfoDate, { [cls.additionalInfoDateError]: !date })}>
                    {date || '--:--'}
                </span>
            </div>
        </span>
    );
});
